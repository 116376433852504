<script>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import deLocale from '@fullcalendar/core/locales/de';
import timeGridPlugin from '@fullcalendar/timegrid'

export default {
  props: ['events', 'clickhandler'],
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    return {
      calendarOptions: {
        locale: deLocale,
        plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin ],
        initialView: 'dayGridMonth',
        eventClick: this.handleDateClick,
        events: this.events ? this.events : [],
        headerToolbar: {
          start: 'dayGridMonth timeGridWeek', // will normally be on the left. if RTL, will be on the right
          center: 'title',
          end: 'today prev,next' // will normally be on the right. if RTL, will be on the left
        },
        nowIndicator: true

      }
    }
  },
  methods: {
    handleDateClick: function(arg) {
      if(this.clickhandler) this.clickhandler(parseInt(arg.event.id))
    }
  }
}
</script>
<template>
  <FullCalendar :options="calendarOptions" />
</template>
<style>

.fc-scrollgrid-sync-inner a {
  color:#000;
}

:root {
  --fc-neutral-text-color: #333 !important;
  --fc-border-color: #ddd !important;

  --fc-button-text-color: #fff !important;
  --fc-button-bg-color: #e6a23c !important;
  --fc-button-border-color: #e6a23c !important;
  --fc-button-hover-bg-color: #f3b252 !important;
  --fc-button-hover-border-color: #f3b252 !important;
  --fc-button-active-bg-color: #f3b252 !important;
  --fc-button-active-border-color: #333 !important;

  --fc-event-bg-color: #e6a23c !important;
  --fc-event-border-color: #999 !important;
  --fc-event-text-color: #fff !important;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25) !important;

  --fc-more-link-bg-color: #e6a23c !important;
  --fc-more-link-text-color: inherit !important;

  --fc-event-resizer-thickness: 8px !important;
  --fc-event-resizer-dot-total-width: 8px !important;
  --fc-event-resizer-dot-border-width: 1px !important;

  --fc-non-business-color: #e6a23c !important;
  --fc-bg-event-color: #e6a23c !important;
  --fc-bg-event-opacity: 0.3 !important;
  --fc-highlight-color: rgba(188, 232, 241, 0.3) !important;
  --fc-today-bg-color: rgba(255, 220, 40, 0.15) !important;
  --fc-now-indicator-color: red !important;
}
</style>
